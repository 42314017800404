<template>
  <div class="donateRes"></div>
</template>

<script>
import { toRegister, gloabalCount } from "@/utils/common.js";
import {} from "./api.js";
var moment = require("moment");
export default {
  name: "donateRes",
  data() {
    return {};
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.donateRes {
  min-height: 100vh;
  padding: 98px 30px;
}
</style>
